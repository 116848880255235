import React from 'react';

const Terms = () => {
  return (
    <div className='terms'>
      <h3 className="animated-text" style={{marginTop:"20px",color:"white",textAlign:"center"}}>
      Terms of Use - Ardent Sport
        </h3>
<br/>
   <h4> 
This Site/application is operated/provided by Superjock Sports LLP, operating under the brand name "Ardent Sport." Superjock Sports LLP is engaged in organizing sports and other recreational events, as well as providing an internet-based booking platform for sports and other events, including sports fitness sessions, sports coaching/outdoor fitness sessions, sports-related training activities, and sports facilities and venues through our Site. These User Terms are a legal contract between you and Ardent Sport. By using the Site and availing the Service, you agree to be bound by these User Terms.</h4>   <h4>
1.	User Responsibilities: By using the Ardent Sport Site/application, you agree to the following:</h4><p>
•	You will use the Service or download the Application for personal use only and will not resell or assign it to a third party.</p><p>
•	You will not use an Account that belongs to another person without appropriate authorization.</p><p>
•	You will not harm the Service, Site, or our network in any way.</p><p>
•	You will provide Ardent Sport with information and documents as reasonably requested.</p><p>
•	You will comply with all applicable laws and regulations.</p><p>
•	You will comply with the Information Technology Act, 2000, and its rules.</p><p>
•	You will only use our Services for legal and authorized purposes.</p><p>
•	You will keep your device and account secure and notify us promptly of any unauthorized use or security breach.</p>
<h4>
2.	Financial Transactions:</h4><p>
•	Entry fees collected by Ardent Sport on behalf of organizers will be transferred to the organizer after the tournament.</p><p>
•	Ardent Sport is not responsible for disputes or issues arising from financial transactions between users and organizers.</p><h4>
3.	Prize Money:</h4><p>
•	The prize money amount displayed in the Ardent Sport app is provided by the tournament organizer.</p><p>
•	Ardent Sport does not assume responsibility for the payment of prize money.</p><p>
•	The responsibility to pay the mentioned prize money to the winners lies with the organizer.</p><h4>
4.	Tie-ups and Joint Ventures:</h4><p>
•	Ardent Sport may enter into tie-ups or joint ventures with other institutions engaged in providing similar services.</p><p>
•	Users give their consent and permission to such tie-ups or joint ventures.</p><p>
•	The terms and conditions mentioned in these User Terms will apply to such tie-up arrangements.</p><h4>
5.	Communications and Privacy:</h4><p>
•	Ardent Sport may send promotional emails and SMS alerts to users unless they opt-out.</p><p>
•	Users' information may be shared with third parties in accordance with our Privacy Policy.</p><h4>
6.	Intellectual Property:</h4><p>
•	Users grant Ardent Sport a non-exclusive, worldwide, perpetual, royalty-free right to exercise their intellectual property rights for the purpose of providing the Services.</p><h4>
7.	Termination:</h4><p>
•	Ardent Sport reserves the right to immediately terminate the Service and use of the Application or Site for non-compliance with the requirements mentioned in these User Terms.
These User Terms are governed by Applicable Laws. By accepting these User Terms, you consent to be bound by them. Please read and understand these User Terms before using the Site or availing any Services. If you do not accept these User Terms, please do not use the Site or the Services.</p>
<h4>
At Ardent Sport, we strive to provide a seamless experience for our users. Please review our refund and cancellation policy outlined below:</h4><p>
1.	Refunds for Competition Entry Fees: Refunds for competition entry fees are subject to the policy set by the organizer of the event. Ardent Sport does not set cancellation and refund policies for the events listed on behalf of third-party service providers on our Site. We ensure that the third-party service providers communicate their refund policy to you through our Site. The responsibility to issue refunds in accordance with their policy lies with the third-party service provider/seller of products. Any refund-related issues or disputes should be directly addressed to the third-party service provider. Ardent Sport shall not be liable for any refund-related claims in connection with Services.</p><p>
2.	Cancellations: Cancellations may be allowed as determined by the organizer of the event. The organizer sets the cancellation policy, and Ardent Sport facilitates the refund process in case of event cancellation. Please refer to the specific event's cancellation policy communicated by the organizer.</p><p>
3.	Bank Charges and Refund Processing Time: In addition to any applicable cancellation charges, the refund amount may be subject to deduction of bank charges, typically 3% of the amount paid. Refunds for failed online ticket bookings will be processed within 7-10 working days.</p><p>
4.	Changes to the Cancellation and Refund Policy: Ardent Sport reserves the right to change or modify the cancellation and refund policy without any prior notice. Any updates to the policy will be reflected on our Site.</p><p>
Please note that Ardent Sport shall not be responsible for any unauthorized transactions conducted on our Site using your payment card, internet banking, or any other method. The Site shall not be obligated to refund any money to you in such instances.</p><p>
Customer Relationship Management: We value your feedback and strive to address any issues or concerns you may have promptly. For any issues, complaints, opinions, suggestions, questions, or feedback related to our Services, please reach out at contact@ardentsport.com. Kindly ensure that your communication is fair, accurate, and non-disparaging.
Reporting of any issues should be done within 7 (seven) days of the occurrence. Please note that issues reported through channels other than the specified email address may be addressed by Ardent Sport on a best-effort basis, and we take no liability for any inability to respond through alternative channels. We strive to respond to your concerns within 7 working days and aim to resolve them as soon as possible. Please understand that issues are prioritized based on severity, and resolution timelines may vary. Ardent Sport shall not be liable for any damages or losses in the event that you are not satisfied with the resolution provided.
If you have any further questions or require assistance, please don't hesitate to contact us.
Thank you for choosing Ardent Sport!</p>
    </div>
  );
}

export default Terms;
