import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Components/Header.mjs";
import Homepage from "./Components/Homepage.mjs";
import Services from "./Components/Services.js";
import Footer from "./Components/Footer.js";
import Privacy from "./Components/Privacy.js";
import Terms from "./Components/Terms.js";

function App() {
  return (
    <Router scrollBehavior="auto">
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/terms" element={<Terms/>}/>
      </Routes>
    </Router>
  );
}

export default App;
