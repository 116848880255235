import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Homepage.css";
import Knockout_Tournament_Final from "../images/2. Knockout Tournament Final.mp4";
import League_Tournament_Final from "../images/4. League Tournament Final.mp4";
import player_booking from "../images/5. Player booking final.mp4";

function Homepage() {
  const [showButtons, setShowButtons] = useState(false);
  const baseURL = "https://res.cloudinary.com/darvxwmt8/image/upload/v1716221694/Website/";

  return (
    <div className="homepageContainer">
      <div className="contentWrapper">
        <div className="leftContent">
          <h3 className="animated-text" id="leftContents">
            CONNECTING PLAYERS TO PASSION ...
          </h3>
          <div className="rightContent1">
            <img className="phone" src={`${baseURL}5.png`} alt="Phone" />
          </div>
          <div className="listf">
            <p><img className="rightcircle" src={`${baseURL}right.png`} alt="Right Icon" /></p>
            <p> Organize Effortlessly, Play Fearlessly <span><img className="lefticon" src={`${baseURL}target.png`} alt="Target Icon" /></span></p>
          </div>
          <div className="listf">
            <p><img className="rightcircle" src={`${baseURL}right.png`} alt="Right Icon" /></p>
            <p> List your sports competition/tournament for FREE <span><img className="lefticon" src={`${baseURL}trophy.png`} alt="Trophy Icon" /></span></p>
          </div>
          <div className="listf">
            <p><img className="rightcircle" src={`${baseURL}right.png`} alt="Right Icon" /></p>
            <p> Digitalize competition experience <span><img className="lefticon" src={`${baseURL}network.png`} alt="Network Icon" /></span></p>
          </div>
          <div className="listf">
            <p><img className="rightcircle" src={`${baseURL}right.png`} alt="Right Icon" /></p>
            <p> Feature your events GLOBALLY <span><img className="lefticon" src={`${baseURL}earth.png`} alt="Earth Icon" /></span></p>
          </div>
          <div className="listf">
            <p><img className="rightcircle" src={`${baseURL}right.png`} alt="Right Icon" /></p>
            <p> Discover sports competition near you <span><img className="lefticon" src={`${baseURL}phone_blue.png`} alt="Blue Phone Icon" /></span></p>
          </div>
          <div>
            <div className="banner_buttons">
              <Link to="https://play.google.com/store/apps/details?id=com.ardent.ardent_sports" className="banner_buttonP" target="_blank">
                <img src={`${baseURL}googleplay.png`} alt="Google Play" />
              </Link>
              <Link to="https://apps.apple.com/us/app/ardent-sports/id6446237100" target="_blank" className="banner_buttonA">
                <img src={`${baseURL}appstore.png`} alt="App Store" />
              </Link>
            </div>
          </div>
          <h3 className="animated-text1 listed_sports_title">LISTED SPORTS</h3>
          <div className="listed_sports">
            <div>
              <img className="card-img-top tt" src={`${baseURL}1.png`} alt="Table Tennis" />
              <div className="card-body">
                <h5 className="card-title" style={{ marginLeft: "2vw" }}>Table Tennis</h5>
              </div>
            </div>
            <div>
              <img className="card-img-top bd" src={`${baseURL}2.png`} alt="Badminton" />
              <div className="card-body">
                <h5 className="card-title bdt">Badminton</h5>
              </div>
            </div>
            <div>
              <img className="card-img-top sq" src={`${baseURL}3.png`} alt="Squash" />
              <div className="card-body squash">
                <h5 className="card-title">Squash</h5>
              </div>
            </div>
            <div>
              <img className="card-img-top lt" src={`${baseURL}4.png`} alt="Lawn Tennis" />
              <div className="card-body">
                <h5 className="card-title ltt">Lawn Tennis</h5>
              </div>
            </div>
          </div>
                   {/* After the Listed Sports section */}
                   <p style={{ textAlign: "center" }}>
            <Link style={{color:"white"}} to="https://events.ardentsport.com" target="_blank" className="exploreT">EXPLORE TOURNAMENTS</Link>
          </p>
          <h3 className="animated-text1 animated-text3">
            ORGANIZER'S DASHBOARD
          </h3>
          <div className="flex1" style={{ display: "flex" }}>
            <span className="knockout">
              <video src={Knockout_Tournament_Final} controls="controls" />
              <h4 style={{ marginLeft: " 3vw" }}>KNOCKOUT</h4>
            </span>
            <span className="contentk">
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Support Knockout, Groups and League Format</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Make Draws/Fixtures</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Create & maintain Points table</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Real time live scoring</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Share the live draws/point table with everyone using a simple link</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Schedule match timings seamlessly</p>
              </div>
              <p style={{ textAlign: "center"}}>
                <Link style={{color:"white"}} to="https://events.ardentsport.com" target="_blank" className="exploreT exploreT3">CREATE TOURNAMENT</Link>
              </p>
            </span>
            <span className="knockout knockout1">
              <video src={League_Tournament_Final} controls="controls" />
              <h4 style={{ marginLeft: " 5vw" }}>LEAGUE</h4>
            </span>
          </div>
          <h3 className="animated-text1 pot">PROUD ORGANIZERS</h3>
          <div className="proud_organizers">
            <div className="listed_sports">
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}10.png`} alt="Ping Pong Club" />
                <div className="card-body ppc">
                  <h5 className="card-title" style={{ fontWeight: "600", marginLeft: "0vw" }}>Ping Pong Club</h5>
                  <p className="card-title New_Delhi">New Delhi</p>
                </div>
              </div>
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}6.png`} alt="Khelo India Dil Se" />
                <div className="card-body ppc">
                  <h5 className="card-title" style={{ fontWeight: "600", marginLeft: "-1vw" }}>Khelo India Dil Se</h5>
                  <p className="card-title New_Delhi">New Delhi</p>
                </div>
              </div>
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}9.png`} alt="JCI Kollam Town" />
                <div className="card-body proudbody ppc">
                  <h5 className="card-title" style={{ fontWeight: "600", marginLeft: "-1vw" }}>JCI Kollam Town</h5>
                  <p className="card-title kerala">Kerala</p>
                </div>
              </div>
            </div>
            <div className="listed_sports">
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}15.png`} alt="Rajkot Badminton Association" />
                <div className="card-body rba">
                  <h5 className="card-title" style={{ fontWeight: "600", marginLeft: "-1vw" }}>Rajkot Badminton Association</h5>
                  <p className="card-title rajcotguj">Rajkot, Gujarat</p>
                </div>
              </div>
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}zimbabwe.jpg`} alt="Zimbabwe Badminton Association" />
                <div className="card-body Zimbabweh5">
                  <h5 className="card-title" style={{ fontWeight: "600", marginLeft: "-3vw" }}>Zimbabwe Badminton Association</h5>
                  <p className="card-title harare">Harare, Zimbabwe</p>
                </div>
              </div>
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}7.png`} alt="Professional Badminton Academy" />
                <div className="card-body pba">
                  <h5 className="card-title Zimbabweh5">Professional Badminton Academy</h5>
                  <p className="card-title akola">Akola, Maharashtra</p>
                </div>
              </div>
            </div>
          </div>
          <h3 className="animated-text1 player_corner_title">PLAYERS CORNER</h3>
          <div style={{ display: "flex" }}>
            <span className="knockout2 knockout">
              <video src={player_booking} controls="controls" />
              <p></p>
              <h4>PLAYER REGISTRATION</h4>
            </span>
            <span className="contentk1">
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Explore the sports tournaments happening globally.</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Create and maintain player profile/records.</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Register in a tournament seamlessly.</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Get real time live score updates of your tournament.</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Unlock the exciting badges in the app by performing on the ground.</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Share the tournament records with your friends.</p>
              </div>
              <div className="listf1">
                <p>
                  <img className="rightcircle" src={`${baseURL}right.png`} />
                </p>
                <p> Analyze yours and opponents game!</p>
              </div>
              <p style={{ textAlign: "center" }}>
                <Link style={{color:"white"}} to="https://events.ardentsport.com" target="_blank" className="exploreT2 exploreT1">EXPLORE MORE!</Link>
              </p>
            </span>
          </div>
          <h3 className="animated-text1 player_corner_title supported_title">
            SUPPORTED BY
          </h3>
          <div className="proud_organizers">
            <div className="listed_sports">
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}8.png`} alt="AICTE & Ministry of Education" />
                <div className="card-body">
                  <h5 className="card-title aiciit" style={{ fontWeight: "600" }}>
                    AICTE & Ministry of Education
                  </h5>
                </div>
              </div>
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}EDII.jpg`} alt="EDII" />
                <div className="card-body">
                  <h5 className="card-title eci" style={{ fontWeight: "600" }}>
                    EDII
                  </h5>
                </div>
              </div>
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}iitmandi.jpg`} alt="IIT Mandi Catalyst" />
                <div className="card-body">
                  <h5 className="card-title aiciit" style={{ fontWeight: "600" }}>
                    IIT Mandi Catalyst
                  </h5>
                </div>
              </div>
            </div>
            <div className="listed_sports">
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}i-hub.png`} alt="i-hub" />
                <div className="card-body">
                  <h5 className="card-title eci" style={{ fontWeight: "600" }}>
                    i-hub
                  </h5>
                </div>
              </div>
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}Cradle.png`} alt="Cradle" />
                <div className="card-body">
                  <h5 className="card-title eci" style={{ fontWeight: "600" }}>
                    Cradle
                  </h5>
                </div>
              </div>
              <div className="proud">
                <img className="card-img-top" src={`${baseURL}startupindia.png`} width={"100px"} alt="Startup India" />
                <div className="card-body">
                  <h5 className="card-title startupI">Startup India</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="footer" style={{ display: "flex" }}>
            <span>
              <p className="twologo">
                <img className="logo2" src={`${baseURL}logo2.png`} alt="Logo" />
                <img className="brand-name1" src={`${baseURL}13.png`} alt="Logo" />
              </p>
              <h4 className="animated-text atbottom" style={{ fontSize: "25px", marginRight: "15vw" }} id="footerid">
                CONNECTING PLAYERS TO PASSION ...
              </h4>
              <div>
                <div className="banner_buttons banner_buttons1">
                  <Link to="https://play.google.com/store/apps/details?id=com.ardent.ardent_sports" className="banner_buttonP" target="_blank">
                    <img className="download" src={`${baseURL}googleplay.png`} alt="Google Play" />
                  </Link>
                  <Link to="https://apps.apple.com/us/app/ardent-sports/id6446237100" className="banner_buttonA" target="_blank">
                    <img className="download" src={`${baseURL}appstore.png`} alt="Apple Store" />
                  </Link>
                </div>
              </div>
            </span>
            <span className="contact" id="contacts">
              <h4 className="emailmo" style={{ color: "#D15858", fontWeight: "600" }}>
                For Support
              </h4>
              <h5 className="email">
                <Link to="mailto:ardentsport@gmail.com" className="emailmo">ardentsport@gmail.com</Link>
              </h5>
              <h5>
                <Link className="emailmo molatest" to="tel:+91 7385026699">+91 7385026699</Link>
              </h5>
              <h5 className="news listf1 molatest">
                For latest news and updates
              </h5>
              <h4 className="follow emailmo">Follow us on</h4>
              <div className="flex">
                <Link to="https://www.instagram.com/ardentsport/?hl=en" target="_blank">
                  <img className="clogo" src={`${baseURL}instagram.png`} alt="Instagram" />
                </Link>
                <Link to="https://www.facebook.com/ardentsport/" target="_blank" className="clogo">
                  <img className="clogo" src={`${baseURL}facebook.png`} alt="Facebook" />
                </Link>
                <Link to="https://www.linkedin.com/company/80687142/admin/" target="_blank" className="clogo">
                  <img className="clogo" src={`${baseURL}linkedin.png`} alt="LinkedIn" />
                </Link>
                <Link to="https://www.youtube.com/@ardentsport6677" target="_blank" className="clogo">
                  <img className="clogo" src={`${baseURL}yaotube.png`} alt="YouTube" />
                </Link>
              </div>
            </span>
          </div>
          <p className="HPTP" style={{ textAlign: "center" }}>
            <a className="HPT" href="/#leftContents">Home</a>
            <Link className="HPT" to="privacy">Privacy Policy</Link>
            <Link className="HPT" to="terms">Terms of Use</Link>
          </p>
          <hr style={{ backgroundColor: "white", height: "1px", marginLeft: "-10vw" }} />
          <p className="allright">Copyright ©️ Superjock Sports LLP. All rights reserved</p>
        </div>
      </div>
    </div>
  );
}

export default Homepage;


