import React from "react";
import "./Homepage.css";
const Privacy = () => {
  return (
    <div className="privacyContainer">
      <div className="privay">
        <h3 className="animated-text" style={{marginTop:"20px",color:"white",textAlign:"center"}}>
        Privacy Policy
        </h3>
        <br/>
        <h5>
          Ardent Sport Welcome to Ardent Sport's website
          (https://ardentsport.com/) and mobile application (collectively
          referred to as the "Platform"). At Ardent Sport, we are committed to
          protecting your privacy and ensuring the security of your personal
          information. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your personal information when you use our
          Platform. By using the Platform, you consent to the data practices
          described in this Privacy Policy.
        </h5>
        <h5>
          1. Information We Collect: We may collect personal information that
          you voluntarily provide to us when you use the Platform. This includes
          your name, email address, phone number, and any other information you
          choose to provide.
        </h5>
        <h5>
          2. How We Use Your Information: We may use the personal information we
          collect for various purposes, including: - Providing and maintaining
          the Platform. - Notifying you about changes to our Platform. -
          Responding to your inquiries and providing customer support. -
          Processing your transactions and payments. - Sending you promotional
          and marketing communications (if you have opted in to receive them). -
          Improving our services and developing new features. - Analyzing usage
          patterns and trends to enhance the user experience. - Protecting the
          security and integrity of our Platform.
        </h5>
        <h5>
          3. Information Sharing and Disclosure: We may share your personal
          information with third parties in the following circumstances: - With
          your consent: We may share your information with third parties if you
          have given us consent to do so. - Service providers: We may engage
          third-party service providers to perform various functions on our
          behalf, such as payment processing, data analysis, customer support,
          and marketing assistance. - Legal compliance: We may disclose your
          information to comply with applicable laws, regulations, or legal
          processes, or in response to a valid request from law enforcement
          authorities or other government officials. - Business transactions: In
          the event of a merger, acquisition, or sale of all or a portion of our
          assets, your information may be transferred as part of the
          transaction. - Protection of rights: We may disclose your information
          to protect and defend our rights, property, or safety, or the rights,
          property, or safety of our users or others.
        </h5>
        <h5>
          4. Data Security: We implement appropriate technical and
          organizational measures to protect your personal information from
          unauthorized access, disclosure, alteration, or destruction. However,
          please note that no method of transmission over the internet or
          electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </h5>
        <h5>
          5. Data Retention: We retain your personal information for as long as
          necessary to fulfill the purposes outlined in this Privacy Policy,
          unless a longer retention period is required or permitted by law.
        </h5>
        <h5>
          6. Third-Party Links and Services: Our Platform may contain links to
          third-party websites, applications, and services that are not owned or
          controlled by Ardent Sport. We are not responsible for the privacy
          practices or content of these third parties. We encourage you to
          review the privacy policies of those third parties when accessing
          their services.
        </h5>
        <h5>
          7. Changes to the Privacy Policy: We may update this Privacy Policy
          from time to time. The updated version will be posted on our Platform,
          and the revised date will be indicated at the top of the page. We
          encourage you to review the Privacy Policy periodically for any
          changes. Your continued use of the Platform after the revised Privacy
          Policy has been posted will signify your acceptance of the
          modifications.
        </h5>
        <h5>
          8. Contact Us: If you have any questions or concerns about this
          Privacy Policy or our data practices, please contact us at
          contact@ardentsport.com. We will respond to your inquiries as soon as
          possible.
        </h5>
        <h5>
          By using our Platform, you acknowledge that you have read, understood,
          and agreed to the terms of this Privacy Policy.
        </h5>
      </div>
    </div>
  );
};

export default Privacy;
